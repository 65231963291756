
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";

export default defineComponent({
  name: "PaymentRecords",
  components: {
    Datatable,
  },
  props: {
    cardClasses: {type: String, default: ""},
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Invoice No.",
        key: "invoice",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortingField: "status.label",
        sortable: true,
      },
      {
        name: "Amount",
        key: "amount",
        sortable: true,
      },
      {
        name: "Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData = ref([
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$880.00",
        status: {
          label: "Pending",
          state: "warning",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Monthly utilites",
        amount: "$7,650.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$375.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "23 Sep 2020, 12:38 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Hosting Fees",
        amount: "$129.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "11 Sep 2020, 3:18 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Marketing automation",
        amount: "$450.00",
        status: {
          label: "Rejected",
          state: "danger",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Sales injection",
        amount: "$8,700.00",
        status: {
          label: "Pending",
          state: "warning",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$1,200.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Google cloud subscription",
        amount: "$79.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Capital investment",
        amount: "$5,500.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$880.00",
        status: {
          label: "Pending",
          state: "warning",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Monthly utilites",
        amount: "$7,650.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Payment for invoice",
        amount: "$375.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "23 Sep 2020, 12:38 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Hosting Fees",
        amount: "$129.00",
        status: {
          label: "Successful",
          state: "success",
        },
        date: "11 Sep 2020, 3:18 pm",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Marketing automation",
        amount: "$450.00",
        status: {
          label: "Rejected",
          state: "danger",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        invoice:
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000) +
          "-" +
          Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        description: "Sales injection",
        amount: "$8,700.00",
        status: {
          label: "Pending",
          state: "warning",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
    ]);

    const deleteRecord = (invoice) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].invoice === invoice) {
          tableData.value.splice(i, 1);
        }
      }
    };

    return { tableHeader, tableData, deleteRecord };
  },
});
